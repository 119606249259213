import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

import PrestationsGrid from "../components/Features";

export const PrestationsPageTemplate = ({
                                            intro,
                                        }) => (
    <div className="content">
        <section className="section section--gradient" style={{ textAlign: 'justify' }}>
            <div className="container">
                <h3 className="has-text-centered" style={{ color : "#b3722d", paddingBottom: "1rem" }}>Les services que nous proposons :</h3>
                <PrestationsGrid gridItems={intro.blurbs} />
            </div>
        </section>
    </div>
)

PrestationsPageTemplate.propTypes = {
    intro: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
}

const PrestationsPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout>
            <PrestationsPageTemplate
                intro={frontmatter.intro}
            />
        </Layout>
    )
}

PrestationsPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default PrestationsPage

export const prestationsPageQuery = graphql`
  query PrestationsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        intro {
          blurbs {  
            imageBlurb {
              childImageSharp {
                fluid(maxWidth: 440, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
            description
          }
        }
      }
    }
  }
`
