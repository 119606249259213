import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImagePrestations from "./PreviewCompatibleImagePrestations";

var paragraphs = require('lines-to-paragraphs');

const FeatureGrid = ({ gridItems }) => (
    <div className="columns is-centered is-multiline" style={{ display : "flex", flexWrap: "wrap", alignItems: "stretch"}}>
        {gridItems.map((item) => (
            <div key={item.text} className="column has-text-centered" style={{minWidth: "350px", maxWidth: "350px", display: "flex"}}>
                <section className="article" style={{ border: "1px solid rgba(0,0,0,.125)", borderRadius: ".25rem", overflow: 'hidden', padding : "0 0 0 0",  width : "33,33%", boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)"}}>
                    <div className="feature_image_wrapper" style={{ width: "350px"}}>
                        <PreviewCompatibleImagePrestations imageInfo={item.imageBlurb}/>
                    </div>
                    <div className="has-text-centered" style={{ padding: '1rem' }}>
                        <h5 style={{ fontWeight: "bold important!"}}>{item.text}</h5>
                        <div dangerouslySetInnerHTML={{ __html: paragraphs(item.description) }} />
                    </div>
                </section>
            </div >
        ))}
    </div >
)

FeatureGrid.propTypes = {
    gridItems: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            text: PropTypes.string,
        })
    ),
}

export default FeatureGrid
